import axios from "axios";
import FormData from "form-data";
import Cookies from "vue-cookies";
const AUTH_ENDPOINT_PATH = "https://api.dev.linktrace.app/api/auth/";

function getUserLogged() {
  let isUserLogged = Cookies.isKey("authData");
  if (isUserLogged) {
    let authData = JSON.parse(JSON.stringify(Cookies.get("authData")));
    return authData;
  } else {
    return false;
  }
}
async function login(IdUsuario, password) {
  var data = new FormData();
  data.append("IdUsuario", IdUsuario);
  data.append("password", password);
  var config = {
    method: "post",
    url: AUTH_ENDPOINT_PATH + "login",
    headers: data.getHeaders
      ? data.getHeaders()
      : { "Content-Type": "multipart/form-data" },
    data: data,
  };
  return await axios(config);
}
async function registerUser(userData) {
  var data = new FormData();
  data.append("IdUsuario", userData.IdUsuario);
  data.append("password", userData.password);
  data.append("RoleName1", userData.RoleName1);
  data.append("RoleName2", userData.RoleName2);
  data.append("NomPersonal", userData.NomPersonal);
  data.append("ApePersonal", userData.ApePersonal);
  data.append("IndHabilitado", "1");

  var config = {
    method: "post",
    url: AUTH_ENDPOINT_PATH + "register",
    headers: data.getHeaders
      ? data.getHeaders()
      : { "Content-Type": "multipart/form-data" },
    data: data,
  };

  return await axios(config);
}
async function logout() {
  var config = {
    method: "post",
    url: AUTH_ENDPOINT_PATH + "logout",
    headers: {
      Authorization: `Bearer ${getUserLogged().access_token}`,
    },
  };
  Cookies.remove("authData");
  localStorage.clear();
  await axios(config)
    .then(function () {
      return true;
    })
    .catch(function (error) {
      console.log(error);
    });
}

async function getUser(id) {
  var data = new FormData();
  data.append("IdUsuario", id);
  var config = {
    method: "post",
    url: AUTH_ENDPOINT_PATH + "user",
    headers: {
      Authorization: `Bearer ${getUserLogged().access_token}`,
    },
    data: data,
  };
  return await axios(config);
}

export default { getUserLogged, login, registerUser, logout, getUser };
