<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div v-if="authData">
    <van-nav-bar
      left-text="Atrás"
      left-arrow
      right-text="Salir"
      @click-left="goBack"
      @click-right="confirmLogOut"
      class="h-[7%]"
    >
      <template #title>
        <span class="text-[#007EAF] drop-shadow-md font-semibold"
          >LINKTRACE</span
        >
      </template>
    </van-nav-bar>
    <router-view
      :authData="authData"
      :isKeyboardOpen="isKeyboardOpen"
      :class="isKeyboardOpen ? 'h-[93%]' : 'h-[86%]'"
      class="overflow-scroll bg-slate-100"
    ></router-view>
    <van-tabbar route v-if="!isKeyboardOpen" class="h-[7%]">
      <van-tabbar-item
        v-for="route in authData.user.RoleName === 'SUPERVISOR'
          ? Tier1Routes
          : Tier2Routes"
        :key="route.name"
        replace
        :to="route.path"
        :icon="route.icon"
        >{{ route.name }}</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
import auth from "@/services/auth";
export default {
  data() {
    return {
      authData: null,
      screenHeight: 0,
      isKeyboardOpen: false,
      Tier1Routes: [
        {
          name: "Rutas",
          path: "/supervisor",
          icon: "cluster-o",
        },
        {
          name: "Crear",
          path: "/add-selector",
          icon: "plus",
        },

        {
          name: "Cuenta",
          path: "/account",
          icon: "user-circle-o",
        },
      ],
      Tier2Routes: [
        {
          name: "Rutas",
          path: "/",
          icon: "cluster-o",
        },
        {
          name: "Completadas",
          path: "/completed-routes",
          icon: "completed",
        },
        {
          name: "Cuenta",
          path: "/account",
          icon: "user-circle-o",
        },
      ],
    };
  },
  methods: {
    confirmLogOut() {
      this.$dialog
        .confirm({
          title: "¿Desea cerrar la sesión activa?",
          message: "Podría perder su progreso si no ha guardado.",
        })
        .then(() => {
          this.logOut();
        })
        .catch(() => {
          console.log("Logout cancelled");
        });
    },
    logOut() {
      this.$toast.loading({
        message: "Saliendo...",
        forbidClick: true,
      });

      auth.logout().then(() => {
        this.$toast.clear();
        this.$vlf.clear().then(() => {
          this.$router.push("/login");
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    windowResized() {
      this.isKeyboardOpen =
        window.screen.orientation.type === "portrait-primary"
          ? window.innerHeight < this.screenHeight
          : false;
    },
  },
  beforeMount() {
    this.screenHeight = window.innerHeight;
    window.addEventListener("resize", this.windowResized);
    this.authData = auth.getUserLogged();
    this.Tier2Routes[0].path = `/${this.authData.user.RoleName.toLowerCase()}`;
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResized);
  },
};
</script>
