import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/services/auth";
import LoginPage from "@/views/Auth/LoginPage.vue";
import { Toast } from "vant";
Vue.use(VueRouter);

const routes = [
  //Base routes
  {
    path: "/canvas",
    component: () => import("../views/MyCanvasPage.vue"),
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: LoginPage,
    meta: { Layout: "PublicLayout" },
  },
  {
    path: "/account",
    component: () => import("../views/Auth/AccountPage.vue"),
  },
  //Supervisor routes
  {
    path: "/supervisor",
    component: () => import("../views/Routes/AdminRoutesPage.vue"),
    meta: { requireRole: "SUPERVISOR" },
  },
  {
    path: "/add-selector",
    component: () => import("../views/Tier1/AddSelectorPage.vue"),
    meta: { requireRole: "SUPERVISOR" },
  },
  {
    path: "/add-route",
    component: () => import("../views/Tier1/AddRoutePage.vue"),
    meta: { requireRole: "SUPERVISOR" },
  },
  {
    path: "/add-user/:id?",
    component: () => import("../views/Tier1/AddUserPage.vue"),
    meta: { requireRole: "SUPERVISOR" },
  },
  //Tendido routes
  {
    path: "/tendido",
    component: () => import("../views/Routes/UserRoutesPage.vue"),
    meta: { requireRole: "tendido" },
  },
  {
    path: "/edit-tendido-poste/:IdRuta/:IdPoste",
    name: "EditTendidoPoste",
    component: () => import("../views/Tier2/TendidoPostePage.vue"),
    meta: { requireRole: "tendido" },
    props: true,
  },
  //Empalmeria routes
  {
    path: "/empalmeria",
    component: () => import("../views/Routes/UserRoutesPage.vue"),
    meta: { requireRole: "empalmeria" },
  },
  {
    path: "/edit-empalmeria-poste/:IdRuta/:IdPoste",
    name: "EditEmpalmeriaPoste",
    component: () => import("../views/Tier2/EmpalmeriaPostePage.vue"),
    meta: { requireRole: "empalmeria" },
    props: true,
  },
  //Mutual routes
  {
    path: "/completed-routes",
    component: () => import("../views/Routes/UserCompletedRoutesPage.vue"),
    meta: { requireRole: "tendido" | "empalmeria" },
  },
  {
    path: "/edit-route/:IdRuta/:NomRuta/:CntPoste/:CntEmpl/:IdSupervisor/:IdRutaUsuario",
    name: "edit-route",
    component: () => import("../views/Tier2/EditRoutePage.vue"),
    meta: { requireRole: "tendido" | "empalmeria" },
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let authData = await auth?.getUserLogged();
  Toast.clear();
  if (!authData) {
    to.path === "/login" ? next() : next("/login");
  } else if (to.path === "/login") {
    next("/" + authData.user.RoleName.toLowerCase());
  } else if (to.meta.requireRole) {
    to.meta.requireRole.toLowerCase() === authData.user.RoleName.toLowerCase()
      ? next()
      : next("/" + authData.user.RoleName.toLowerCase());
  } else {
    next();
  }
});
export default router;
