import Vue from "vue";
import App from "./App.vue";

import { createPinia, PiniaVuePlugin } from "pinia";
import VueCompositionAPI from "@vue/composition-api";
import { VuePersistentStorageManager } from "vue-persistent-storage-manager";
Vue.use(VuePersistentStorageManager, { watchStorage: true });

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(VueCompositionAPI);

import router from "./router";
import VueCookies from "vue-cookies";

import Vant from "vant";
import "vant/lib/index.css";
import { Locale } from "vant";
import esES from "vant/es/locale/lang/es-ES";
Locale.use("es-ES", esES);

import "./assets/tailwind.css";

import Vlf from "vlf";
import localforage from "localforage";
localforage.config({
  driver: localforage.INDEXEDDB,
  name: "LinkTrace",
  version: 1.0,
  storeName: "link_trace_db",
  description: "Temp data storage",
});
Vue.use(Vlf, localforage);

import MainLayout from "@/layouts/MainLayout.vue";
import PublicLayout from "@/layouts/PublicLayout.vue";

Vue.component("PublicLayout", PublicLayout);
Vue.component("MainLayout", MainLayout);

Vue.config.productionTip = false;
Vue.use(VueCookies, { expire: "7d" });
Vue.use(Vant);

new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount("#app");
