<template>
  <div class="relative h-screen w-full bg-slate-100 overflow-hidden">
    <div class="sm:hidden absolute top-0 left-0 login-blob-1"></div>
    <div class="sm:hidden absolute top-0 left-0 login-blob-2"></div>
    <div class="absolute w-2/5 top-[8%] left-2/4 -translate-x-2/4">
      <img :src="mainLogoInverted" />
    </div>
    <div class="w-4/5 sm:w-2/6 absolute top-2/4 left-2/4 -translate-x-2/4">
      <van-form @submit="login()">
        <div class="shadow-md rounded-lg bg-white py-6 px-4">
          <van-field
            v-model="IdUsuario"
            name="IdUsuario"
            type="number"
            placeholder="Identificador"
            :rules="[
              { required: true, message: 'El nombre de usuario es requerido' },
            ]"
          />
          <van-field
            v-model="Password"
            type="Password"
            name="Password"
            placeholder="Contraseña"
            :rules="[{ required: true, message: 'La contraseña es requerida' }]"
          />
        </div>
        <van-button class="rounded-md w-full mt-10" type="info">
          <van-loading
            v-show="isLoading"
            size="24px"
            type="spinner"
            color="#fff"
          />
          <span v-show="!isLoading">Iniciar sesión</span>
        </van-button>
      </van-form>
    </div>
    <van-dialog
      v-model="showRoleSelector"
      title="Seleccione su Rol"
      @confirm="confirmRole"
    >
      <van-row>
        <van-col offset="6" span="12">
          <div>
            <van-button
              class="rounded-md w-full mt-10 py-10"
              :plain="selectedRole === 'EMPALMERIA' ? false : true"
              @click="selectedRole = 'EMPALMERIA'"
              type="info"
            >
              <van-icon name="manager-o" />
              <div>Empalmeria</div>
            </van-button>
          </div>
        </van-col>
        <van-col offset="6" span="12">
          <div>
            <van-button
              class="rounded-md w-full my-10 py-10"
              :plain="selectedRole === 'TENDIDO' ? false : true"
              @click="selectedRole = 'TENDIDO'"
              type="info"
            >
              <van-icon name="manager-o" />
              <div>Tendido</div>
            </van-button>
          </div>
        </van-col>
      </van-row>
    </van-dialog>
  </div>
</template>
<script>
import auth from "@/services/auth";
import mainLogo from "@/assets/LinkTrace.svg";
import mainLogoInverted from "@/assets/LinkTrace - Inverted.svg";
import logoText from "@/assets/LinkTrace-Text.svg";
import { getActivePinia } from "pinia";
export default {
  data() {
    return {
      IdUsuario: "",
      Password: "",
      LoginError: null,
      isLoading: false,
      mainLogo,
      mainLogoInverted,
      logoText,
      showRoleSelector: false,
      selectedRole: null,
    };
  },
  methods: {
    login() {
      this.isLoading = true;
      auth
        .login(this.IdUsuario, this.Password)
        .then((response) => {
          this.isLoading = false;
          let hasTwoRoles =
            response.data.user.RoleName2 === "TENDIDO" ||
            response.data.user.RoleName2 === "EMPALMERIA";
          if (hasTwoRoles) {
            this.$cookies.set("authData", JSON.stringify(response.data));
            this.showRoleSelector = true;
          } else {
            this.$cookies.set(
              "authData",
              JSON.stringify({
                ...response.data,
                user: {
                  ...response.data.user,
                  RoleName: response.data.user.RoleName1,
                },
              })
            );
            this.$router.push("/account");
          }
        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
          this.$toast.fail("Credenciales incorrectas");
        });
    },
    confirmRole() {
      this.$cookies.set(
        "authData",
        JSON.stringify({
          ...this.$cookies.get("authData"),
          user: {
            ...this.$cookies.get("authData").user,
            RoleName:
              this.selectedRole || this.$cookies.get("authData").user.RoleName1,
          },
        })
      );
      this.$router.push("/account");
    },
  },
  mounted() {
    if (
      this.$storageManager.isAvailable &&
      !this.$storageManager.isPersistent
    ) {
      this.$storageManager.requestPersistentStorage();
    }
    const pinia = getActivePinia();
    pinia._s.forEach((store) => store.$reset());
  },
};
</script>
<style scoped>
.login-blob-1 {
  width: 100%;
  height: 45%;
  transform: translateY(-20%) scale(1.3);
  border-radius: 0% 100% 83% 17% / 100% 47% 53% 0%;
  background: linear-gradient(
    to right,
    rgba(10, 56, 155, 0.89) 0%,
    rgba(19, 149, 235, 0.836) 100%
  );
}
.login-blob-2 {
  width: 100%;
  height: 45%;
  transform: translateY(-20%) scale(1.3);
  border-radius: 0% 100% 83% 17% / 100% 9% 91% 0%;
  background: linear-gradient(
    to right,
    rgba(10, 56, 155, 0.89) 0%,
    rgba(10, 56, 155, 0.3) 100%
  );
}
</style>
